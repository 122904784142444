<template>
    <OModal ref="modal" @hidden="clearAllFields">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('Add New Customers') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-5 form-group">
                            <label>{{ $t("Name") }}*</label>
                            <input class="form-control" v-model="customerName" />
                        </div>
                        <div class="col-7" v-if="showDuplicateGrid">
                            <OColContainer style="max-height:500px">
                                <ODataGrid :dataObject="dsPossibleDuplicates" 
                                    hideGridMenu 
                                    hideMultiselectColumn
                                >
                                    <template #cardheader>
                                        <h5 class="ms-2">{{ $t('Possible Duplicates') }}</h5>
                                    </template>
                                    <OColumn field="Customer_ID" width="60" :headerName="$t('ID')" sortable :cellrendererparams="{ href: '/customer?ID={{Customer_ID}}&LegalEntity_ID={{LegalEntity_ID}}', target:'_blank' }" v-slot="{row, column}">
                                        <OLink :modelValue="row" :column="column"></OLink>
                                    </OColumn>
                                    <OColumn field="Name" width="385" :headerName="$t('Name')" sortable/>
                                    <OColumn field="Closed" width="95" :headerName="$t('Closed')" sortable/>
                                </ODataGrid>
                            </OColContainer>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" :disabled="isLoading" @click="createNewCustomer">
                        <div v-if="isLoading" class="spinner-border spinner-border-sm" style="0.35rem" role="status">
                            <span class="visually-hidden">{{ $t('Loading...') }}</span>
                        </div>
                        {{ $t("Add Customer") }}
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("Cancel") }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    import { ref, defineExpose, watch } from 'vue';
    import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
    import context from 'o365.modules.Context.ts';

    const dsPossibleDuplicates = getOrCreateDataObject({
        id: 'dsPossibleDuplicates' + crypto.randomUUID(),
        viewName: 'atbv_Accounts_CustomersDuplicatesSearchResults',
        uniqueTable: null,
        distinctRows: false,
        selectFirstRowOnLoad: true,
        maxRecords: 25,
        allowInsert: false,
        allowUpdate: false,
        allowDelete: false,
        fields: [
            { name: "PrimKey", type: "string" },
            { name: "ID", type: "Number" },
            { name: "Name", type: "string", sortOrder: 1, sortDirection: "asc"},
            { name: "LegalEntity_ID", type: "Number"},
            { name: "Closed", type: "date"},
            { name: "Customer_ID", type: "Number"}
        ]
    });  

    dsPossibleDuplicates.on("DataLoaded", ()=>{showDuplicateGrid.value = true;})

    const procAddCustomer = getOrCreateProcedure(
        {
            id: 'procAddCustomer' + crypto.randomUUID, 
            procedureName: 'astp_Accounts_AddCustomer'
        }
    );
    const procDuplicateSearch = getOrCreateProcedure(
        {
            id: 'procDuplicateSearch' + crypto.randomUUID, 
            procedureName: 'astp_Accounts_CustomersDuplicateSearch'
        }
    );
    const emit = defineEmits(["CustomerCreated"]);

    const modal = ref(null);
    const showDuplicateGrid = ref(false);
    const isLoading = ref(false);
    const customerName = ref(null);

    const openModal = () => {
        modal.value.show();
    };
    const hideModal = () =>{
        modal.value.hide();
    }
    defineExpose({ openModal, hideModal });

    watch(customerName, doSearch);

    function doSearch(){
        procDuplicateSearch.execute({
            SearchTerm: customerName.value,
            LegalEntity_ID: context.id
        }).then(()=>{
            dsPossibleDuplicates.load();
        });
    }

    function createNewCustomer(){
        isLoading.value = true;
        procAddCustomer.execute({
            Name: customerName.value,
            LegalEntity_ID: context.id
        }).then((pData)=>{
            emit("CustomerCreated", pData.Table[0].ID)
            isLoading.value = false;
            showDuplicateGrid.value = true;
        }).catch(()=>{
            isLoading.value = false;
        })
    }

    function clearAllFields(){
        customerName.value = null;
        showDuplicateGrid.value = false;
    }
</script>